import Head from 'next/head'
import React, { FC } from 'react'
import { HeadMetaProps } from '../../types'

export const HeadMeta: FC<HeadMetaProps> = ({
    title = "FKAM URL Shortener",
    description = "Dashboard URL Shortener Forum Komunikasi Aktivis Masjid",
    url= "https://url.fkam.id",
    author = "FKAM",
    image = "https://trustberbagi.org/images/trustberbagi-cover.png",
    key = "fkam url shortener",
    keyword = "url, link, short, shortener, url shortener, link shortener, fkam, masjid, aktivis, forum, komunikasi",
    themeColor = "#ffff",
}) => {
    return (
        <Head>
            <title>{title}</title>
            <link rel="icon" href="/favicon.ico" />
            <link rel="canonical" href={url} />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />

            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <meta name="author" content={author} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="theme-color" content={themeColor} />

            <meta property="og:site_name" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} key={key} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Head>
    )
}
