import { create } from 'zustand';

type UserType = {
  id: number;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
} | null;

type UserState = {
  user: UserType | null;
  setUserState: (value: UserType | null) => void;
};

export const userState = create<UserState>((set) => ({
  user: null,
  setUserState: async (value: UserType | null) => {
    await set(() => ({
      user: value,
    }));
  },
}));
