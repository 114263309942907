import type { AppProps } from 'next/app';
import { ConfigProvider } from 'antd';
import idID from 'antd/locale/id_ID';
import { StyleProvider } from '@ant-design/cssinjs';
import '../styles/globals.css';
import dayjs from 'dayjs';
import { MainLayout } from '../components/layouts/MainLayout';
require('dayjs/locale/id');

dayjs.locale('id');

const themeConfigAntd = {
  token: {
    colorPrimary: '#4D42E9',
  },
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ConfigProvider locale={idID} theme={themeConfigAntd}>
      <StyleProvider hashPriority="high">
        <MainLayout>
          <Component {...pageProps} />
        </MainLayout>
      </StyleProvider>
    </ConfigProvider>
  );
}
