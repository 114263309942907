import React, { FC, useCallback, useEffect, useState } from 'react';
import { userState } from '../../store/user.state';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';
import * as cookie from 'cookies-next';
import { COOKIE_NAME, COOKIE_NAME_API } from '../../data/cookie';
import fetcher from '../../utils/fetcher.util';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { HeadMeta } from '../meta/HeadMeta';

export const MainLayout: FC<any> = (props) => {
  const router = useRouter();
  const { setUserState, user } = userState((state) => state);
  const [isExistToken, setIsExistToken] = useState<boolean>(false);
  const { data: userData, error } = useSWR(isExistToken ? `/api/v1/users/profile` : null, fetcher);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routePathnamaeAuthenticated = ['/', '/url', '/accounts', '/new-url', '/setting'];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prefixesToCheck = ['/url', '/accounts', '/new-url', '/setting'];
  const pathMatched = useCallback(
    () => prefixesToCheck.some((prefix) => router.pathname.startsWith(prefix)) || routePathnamaeAuthenticated.includes(router.pathname),
    [router, prefixesToCheck, routePathnamaeAuthenticated]
  );

  const getToken = () => {
    const token = cookie.getCookie(COOKIE_NAME) as string;
    setIsExistToken(() => (token ? true : false));
  };

  useEffect(() => {
    const initData = async () => {
      await getToken();
      if (!user || !isExistToken || !userData) {
        if (!user && isExistToken && userData) {
          await setUserState(userData);
          return;
        }
        // console.log(error)
        // cookie.deleteCookie(COOKIE_NAME_API)
        // cookie.deleteCookie(COOKIE_NAME)
        // router.push('/login')
      }
    };

    initData().catch((err) => {
      console.log(error);
      if (pathMatched()) {
        cookie.deleteCookie(COOKIE_NAME_API);
        cookie.deleteCookie(COOKIE_NAME);
        router.replace('/login');
        return;
      }
    });
  }, [user, isExistToken, userData, router, setUserState, error, pathMatched]);

  return (
    <>
      <HeadMeta />
      <main className="flex flex-col h-screen">
        {isExistToken && pathMatched() && user && <Navbar />}
        <div className="w-full flex">
          {isExistToken && pathMatched() && user && <Sidebar />}
          <div className="flex-grow w-5/6">
            <div className="h-[10vh]"></div>
            {props.children}
          </div>
        </div>
      </main>
    </>
  );
};
