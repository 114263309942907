import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Tooltip } from 'antd'
import { SidebarItemProp } from '../../../types'

export const SidebarItem: FC<SidebarItemProp> = ({
    activeClassName = '!bg-primary/80 hover:!bg-primary/80 text-white',
    ...props
}) => {
    const { asPath, ...router } = useRouter()

    const handleRoute = () => {
        router.push(props?.href)
    }

    const childClassName = `cursor-pointer bg-white hover:bg-primary/20 rounded-lg px-2 md:px-5 py-2 flex items-center gap-2`
    const pathName = props.href.split('/')
    const className =
        (asPath === props.href || asPath === `/${pathName[1]}`)
            ? `${childClassName} ${activeClassName}`.trim()
            : childClassName;

    const iconCurrent =
        (asPath === props.href || asPath === `/${pathName[1]}`)
            ? props.activeIcon
            : props.icon;

    return (
        <>
            <Tooltip placement="rightTop" title={props?.label} className="flex md:hidden" color={'#4D42E9'}>
                <div
                    className={className}
                    onClick={handleRoute}
                >
                    {iconCurrent ?? null}

                    <div className='hidden md:flex'>
                        {props.label}
                    </div>
                </div>
            </Tooltip>
            <div
                className={'hidden md:flex ' + className}
                onClick={handleRoute}
            >
                {iconCurrent ?? null}

                <div className='hidden md:flex'>
                    {props.label}
                </div>
            </div>
        </>
    )
}
