import React, { FC, useEffect } from 'react'
import { SidebarItem } from './sidebar/SidebarItem'
import { HiHome, HiOutlineHome, HiOutlineUsers, HiUsers } from 'react-icons/hi'
import { MdOutlineAddLink } from 'react-icons/md'
import { BsGear, BsGearFill } from 'react-icons/bs'
import { useUser } from '../../hooks/useUser'
import { useSidebar } from '../../hooks/useSidebar'

export const Sidebar: FC = () => {
  const { user, getUserData } = useUser()
  const { isShow } = useSidebar()

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {console.log('isshow', isShow);
  }, [isShow])

  return (
    <div className={`${isShow ? '' : ''} bg-white sticky top-0 left-0 shadow w-1/6 flex-grow h-screen z-[5]`}>
      <div className='h-[10vh]'></div>
      <div className='py-5 px-2 flex-grow h-[90vh] flex flex-col gap-2 overflow-auto'>
        <SidebarItem href='/'
          icon={<HiOutlineHome className='h-5 w-5 mx-auto md:mx-0' />}
          activeIcon={<HiHome className='h-5 w-5 mx-auto md:mx-0' />}
          label="Home"
        />
        <SidebarItem href='/new-url'
          icon={<MdOutlineAddLink className='h-5 w-5 mx-auto md:mx-0' />}
          activeIcon={<MdOutlineAddLink className='h-5 w-5 mx-auto md:mx-0' />}
          label="Buat Baru"
        />
        {user?.role === 'admin' ?
          <SidebarItem href='/accounts'
            icon={<HiOutlineUsers className='h-5 w-5 mx-auto md:mx-0' />}
            activeIcon={<HiUsers className='h-5 w-5 mx-auto md:mx-0' />}
            label="Akun"
          />
          : null
        }
        <SidebarItem href='/setting'
          icon={<BsGear className='h-5 w-5 mx-auto md:mx-0' />}
          activeIcon={<BsGearFill className='h-5 w-5 mx-auto md:mx-0' />}
          label="Pengaturan"
        />
      </div>
    </div>
  )
}
