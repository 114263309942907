import axios from 'axios'
import * as cookie from 'cookies-next'
import { COOKIE_NAME, COOKIE_NAME_API } from '../data/cookie'

export const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    headers: {
        'x-token': cookie.getCookie(COOKIE_NAME_API),
    },
})

axiosInstance.interceptors.request.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500

    if (!expectedError) {
        console.log('an unexpected error occurred!')
    }
    Promise.reject(error)
})
