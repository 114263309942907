import React, { FC } from 'react'
import { BaseButton } from '../buttons/BaseButton'
import * as cookie from 'cookies-next'
import { COOKIE_NAME, COOKIE_NAME_API } from '../../data/cookie'
import { useRouter } from 'next/router'
import { RiLinksFill } from 'react-icons/ri'
import { Button } from 'antd'
import { userState } from '../../store/user.state'
import { SIDEBAR_SHOW } from '../../data/constanta'
import { useSidebar } from '../../hooks/useSidebar'

export const Navbar: FC = () => {
  const router = useRouter()
  const { toggleSidebar } = useSidebar()
  const { user } = userState(state => state)

  const handleLogout = async () => {
    await cookie.deleteCookie(COOKIE_NAME)
    await cookie.deleteCookie(COOKIE_NAME_API)
    window.location.pathname = '/login'
    // router.push('/login')
  }

  return (
    <div className='fixed z-10 top-0 p-5 h-[10vh] w-full bg-white flex items-center justify-between border-b'>
      <div className='flex items-center gap-2'>
        {/* <div
          onClick={toggleSidebar}
        >
          hidden
        </div> */}
        <RiLinksFill className='text-primary h-8 w-8' />
        <h1 className='text-primary font-semibold hidden md:flex'>
          FKAM URL Shortener
        </h1>
      </div>

      <div className='flex gap-5 items-center'>
        <h5 className='text-xs italic text-right'>
          {user?.name ?? '-'} | {user?.email ?? '-'}
        </h5>

        <Button
          onClick={handleLogout}
          className="hover:bg-rose-100"
        >
          Logout
        </Button>
      </div>
    </div>
  )
}
