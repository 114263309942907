import { useState } from "react"
import * as cookie from 'cookies-next'
import { COOKIE_NAME } from "../data/cookie"
import { jwtVerify } from 'jose'
import { useRouter } from "next/router"

export const useUser = () => {
    const router = useRouter()
    const [user, setUser] = useState<any>(null)

    const getUserData = async () => {
        const token = cookie.getCookie(COOKIE_NAME) as string
        const SECRET_KEY: string = process.env.NEXT_PUBLIC_SECRET_KEY as string;

        try {
            const { payload } = await jwtVerify(token, new TextEncoder().encode(SECRET_KEY));
            setUser(payload)
        } catch (error) {
            cookie.deleteCookie(COOKIE_NAME)
            router.push('/login')
        }
    }

    return {
        user,
        getUserData
    }
}