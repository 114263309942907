import { useEffect, useState } from "react"
import { SIDEBAR_SHOW } from "../data/constanta"

export const useSidebar = () => {
    const [isShow, setIsShow] = useState<boolean>(true)

    const getSidebarStatus = async () => {
        const sidebarStatus = localStorage.getItem(SIDEBAR_SHOW)
        setIsShow(sidebarStatus === 'true' ? true : false)
    }

    const toggleSidebar = () => {
        const sidebarStatus = localStorage.getItem(SIDEBAR_SHOW)
        setIsShow(() => sidebarStatus === 'true' ? false : true)
        localStorage.setItem(SIDEBAR_SHOW, sidebarStatus === 'true' ? 'false' : 'true')
    }

    useEffect(() => {
        getSidebarStatus()
    }, [isShow])

    return {
        isShow,
        toggleSidebar
    }
}